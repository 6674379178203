<div
  elTestId="jQcu11xiN-ScQ7UjSTGUB"
  fxLayout="column"
  fxLayoutGap="10px"
  class="banner-container"
  [ngClass]="{
    'insight-content': (iotInsightService.actionTypeIsInsight$ | async)
  }"
>
  <div
    elTestId="gXZbJCOHalYLsJ8dOFKh9"
    class="banner"
    *ngIf="
      displaySystemMaintenanceBanner && (showSystemMaintenanceMessage$ | async)
    "
  >
    <app-system-banner
      [message]="(systemMaintenanceMsgHTML$ | async) || ''"
      [type]="(systemMaintenanceMsg$ | async)?.type || bannerType.INFO"
      (closed)="closeSystemBanner()"
    >
    </app-system-banner>
  </div>

  <div
    elTestId="6vD659vXSd5ABEDBSAiFB"
    class="banner"
    *ngIf="displaySubscriptionBanner && (showMaintenanceMessage$ | async)"
  >
    <app-system-banner
      [message]="(subscriptionMaintenanceMsgHTML$ | async) || ''"
      [type]="(maintenanceMsg$ | async)?.type || bannerType.INFO"
      (closed)="closeSubscriptionBanner()"
    >
    </app-system-banner>
  </div>
</div>
